import { IconLightning } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

export type ConfirmDuplicationModalProps = {
    sourceFieldName: string;
    open: boolean;
    onClose: (selectedFieldsNbr: number) => void;
    onConfirm: () => void;
    isLoading: boolean;
};

export const ConfirmDuplicationModal = ({
    sourceFieldName,
    open,
    onClose,
    onConfirm,
    isLoading,
}: ConfirmDuplicationModalProps) => {
    const { t } = useTranslation();
    const { fieldCropIds, operationIds, hasSameTillageId, hasSameOrganic, hasSameCompanionCrop } =
        useOperationDuplicationFormValues();
    const amountToCopy =
        operationIds.length + (hasSameOrganic ? 1 : 0) + (hasSameTillageId ? 1 : 0) + (hasSameCompanionCrop ? 1 : 0);

    return (
        <ConfirmModal
            isOpen={open}
            size={'medium'}
            onClose={({ confirmed }) => (confirmed ? onConfirm() : onClose(fieldCropIds.length))}
            title={t('encoding-technical-itinerary.duplication.modal.confirm-modal.title')}
            primaryText={{
                translationKey: 'encoding-technical-itinerary.duplication.modal.confirm-modal.summary',
                translationValues: {
                    operationsCount: amountToCopy,
                    sourceFieldName,
                    targetFieldsCount: fieldCropIds.length,
                },
            }}
            secondaryText={{
                translationKey: 'encoding-technical-itinerary.duplication.modal.confirm-modal.description',
            }}
            confirmLoading={isLoading}
            confirmText={
                <>
                    <IconLightning />
                    {t('encoding-technical-itinerary.duplication.modal.confirm-modal.confirm-and-copy')}
                </>
            }
        />
    );
};
