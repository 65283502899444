import { deleteLayersByPattern } from '../utils/polygonHelper';
import { useCallback, useContext, useEffect, useState } from 'react';
import { MapContext } from '@shared/map/utils/MapProvider';
import mapboxgl from 'mapbox-gl';
import { getTextLayerProps, getTextLayoutLabel } from '../utils/layerHelper';
import { POLYGON_SLUGS, cropNamesT } from '../types/mapTypes';
import { useFormContext } from 'react-hook-form';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { FieldT } from '@shared/entities';
import { fieldWithCropT } from '../types/mapTypes';
import setupCropPalette from '../utils/setupCropPalette';
import { FieldPolygonT } from '@shared/entities/field/field.types';

export type OnPolygonClickT = (props: { polygonId: number }) => { continueInternalBehavior: boolean };

export const usePolygon = (
    isSelectable?: boolean,
    sourceField?: DuplicationFieldT,
    /**
     * Handle action on polygon click from outside.
     * If return continueInternalBehavior: true, it will continue the internal actions (fill forms, update map state...)
     */
    onPolygonClick?: OnPolygonClickT,
) => {
    const mapContext = useContext(MapContext);
    const [hoveredFeatureFieldOverview, setHoveredFeatureFieldOverview] = useState<FieldPolygonT | undefined>();

    if (!mapContext) throw new Error('usePolygon should be used inside a MapProvider');

    const methods = useFormContext();

    const { map, fields, selectedFields, setSelectedFields } = mapContext;

    const addPolygonLayer = useCallback(() => {
        if (!map) return;

        if (fields) {
            const fieldsIds = fields.map((field) => field.id);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON_OUTLINE, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON_TEXT, fieldsIds);

            let computedCropNames = '';
            let cropColors: { [key: number]: string } = {};
            if (fields.some((field) => 'crops' in field)) {
                const fieldsWithCrop = fields.filter((field): field is fieldWithCropT => 'crops' in field);
                cropColors = setupCropPalette(fieldsWithCrop as fieldWithCropT[]);
            }
            fields.forEach((field: FieldT | DuplicationFieldT | fieldWithCropT) => {
                let hasCrop = false;
                let fieldCropColor = 'white';
                if ('crops' in field) {
                    computedCropNames = field.crops.map((crop: cropNamesT) => crop.name).join(' • ');
                    hasCrop = field.crops.length > 0;
                    fieldCropColor = hasCrop ? cropColors[field.crops[0].id] : 'white';
                }

                const fillLayerId = `${POLYGON_SLUGS.POLYGON}-${field.id}`;
                const outlineLayerId = `${POLYGON_SLUGS.POLYGON_OUTLINE}-${field.id}`;
                const textLayerId = `${POLYGON_SLUGS.POLYGON_TEXT}-${field.id}`;

                map.on('mousemove', fillLayerId, function (e) {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: [fillLayerId],
                    });
                    if (features.length > 0) {
                        setHoveredFeatureFieldOverview(field.polygon);
                    } else {
                        map.getCanvas().style.cursor = '';
                        setHoveredFeatureFieldOverview(undefined);
                    }
                });

                map.on('mouseleave', fillLayerId, function () {
                    map.getCanvas().style.cursor = '';
                    setHoveredFeatureFieldOverview(undefined);
                });

                const fillLayer = map.getLayer(fillLayerId);
                if (!fillLayer) {
                    map.addLayer({
                        id: fillLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-color': fieldCropColor,
                            'fill-opacity': 0.6,
                        },
                    });
                } else {
                    map.setPaintProperty(fillLayerId, 'fill-color', fieldCropColor);
                    (map.getSource(fillLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const outlineLayer = map.getLayer(outlineLayerId);
                if (!outlineLayer) {
                    map.addLayer({
                        id: outlineLayerId,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'line-color': fieldCropColor,
                            'line-width': 1.5,
                        },
                    });
                } else {
                    map.setPaintProperty(outlineLayerId, 'line-color', fieldCropColor);
                    (map.getSource(outlineLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                if (!map.getLayer(textLayerId)) {
                    map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.POLYGON_TEXT, computedCropNames));
                } else {
                    const newTextLayoutLabel = getTextLayoutLabel(field, computedCropNames);
                    map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    (map.getSource(textLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                if (isSelectable) {
                    map.on('mousemove', fillLayerId, function () {
                        map.getCanvas().style.cursor = 'pointer';
                    });

                    map.on('mouseleave', fillLayerId, function () {
                        map.getCanvas().style.cursor = 'default';
                    });
                    map.on('click', `${POLYGON_SLUGS.POLYGON}-${field.id}`, () => {
                        if ('fieldCrop' in field) {
                            if (field.id === sourceField?.id) return;
                            // if the click is not allowed from outside the hook, we stop the action
                            if (onPolygonClick && !onPolygonClick({ polygonId: field.id }).continueInternalBehavior) {
                                return;
                            }
                            const cropId = (field as DuplicationFieldT).fieldCrop.id;
                            const currentCropIds = methods.getValues('target_farm_season_field_crop_ids');
                            if (!currentCropIds.includes(cropId)) {
                                methods.setValue('target_farm_season_field_crop_ids', [...currentCropIds, cropId]);
                            }
                        } else {
                            // if the click is not allowed from outside the hook, we stop the action
                            if (onPolygonClick && !onPolygonClick({ polygonId: field.id }).continueInternalBehavior) {
                                return;
                            }
                            const id = field.id;
                            const currentFieldIds = methods.getValues('selected_field_ids');
                            if (currentFieldIds.includes(id)) return;
                            methods.setValue('selected_field_ids', [...methods.getValues('selected_field_ids'), id]);
                            setSelectedFields?.((prevSelectedFields) => [...prevSelectedFields, field]);
                        }
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, isSelectable, map, setSelectedFields, sourceField?.id, methods]);

    const addSelectedPolygonLayer = useCallback(() => {
        if (!map) return;

        if (selectedFields) {
            const selectedFieldsIds = selectedFields.map((field) => field.id);

            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON, selectedFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON_OUTLINE, selectedFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON_TEXT, selectedFieldsIds);

            selectedFields.forEach(
                (field: FieldT | DuplicationFieldT | fieldWithCropT | DuplicationFieldT | fieldWithCropT) => {
                    let cropNames = '';
                    if ('crops' in field) {
                        cropNames = field.crops.map((crop) => crop.name).join(' • ');
                    }
                    const fillLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON}-${field.id}`;
                    const outlineLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON_OUTLINE}-${field.id}`;
                    const textLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON_TEXT}-${field.id}`;

                    if (!map.getLayer(fillLayerId)) {
                        map.addLayer({
                            id: fillLayerId,
                            type: 'fill',
                            source: {
                                type: 'geojson',
                                data: field.polygon,
                            },
                            paint: {
                                'fill-color': 'green',
                                'fill-opacity': 0.6,
                            },
                        });
                    }

                    if (!map.getLayer(outlineLayerId)) {
                        map.addLayer({
                            id: outlineLayerId,
                            type: 'line',
                            source: {
                                type: 'geojson',
                                data: field.polygon,
                            },
                            paint: {
                                'line-color': 'green',
                                'line-width': 1.5,
                            },
                        });
                    }

                    if (!map.getLayer(textLayerId)) {
                        map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.SELECTED_POLYGON_TEXT, cropNames));
                    } else {
                        const newTextLayoutLabel = getTextLayoutLabel(field, cropNames);
                        map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    }
                    if (isSelectable) {
                        map.on('mousemove', fillLayerId, function () {
                            map.getCanvas().style.cursor = 'pointer';
                        });

                        map.on('mouseleave', fillLayerId, function () {
                            map.getCanvas().style.cursor = 'default';
                        });

                        map.on('click', fillLayerId, () => {
                            //handle the case where the field has a crop (duplication)
                            if ('fieldCrop' in field) {
                                if (field.id === sourceField?.id) return;
                                // if the click is not allowed from outside the hook, we stop the action
                                if (
                                    onPolygonClick &&
                                    !onPolygonClick({ polygonId: field.id }).continueInternalBehavior
                                ) {
                                    return;
                                }
                                const cropId = (field as DuplicationFieldT).fieldCrop.id;
                                const currentCropIds = methods.getValues('target_farm_season_field_crop_ids');
                                const updatedCropIds = currentCropIds.filter((id: number) => id !== cropId);
                                methods.setValue('target_farm_season_field_crop_ids', updatedCropIds);
                            } else {
                                // if the click is not allowed from outside the hook, we stop the action
                                if (
                                    onPolygonClick &&
                                    !onPolygonClick({ polygonId: field.id }).continueInternalBehavior
                                ) {
                                    return;
                                }
                                const fieldId = field.id;
                                const currentfieldIds = methods.getValues('selected_field_ids');
                                const updatedfieldIds = currentfieldIds.filter((id: number) => id !== fieldId);
                                methods.setValue('selected_field_ids', updatedfieldIds);
                                setSelectedFields?.((prevSelectedFields) =>
                                    prevSelectedFields.filter((f) => f.id !== field.id),
                                );
                            }
                        });
                    }
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectable, map, selectedFields, setSelectedFields, sourceField?.id, methods]);

    useEffect(() => {
        if (map && fields) {
            if (!map.loaded()) {
                map.once('idle', () => {
                    addPolygonLayer();
                });
            } else {
                addPolygonLayer();
            }
        }
        // missing addPolygonLayer in the dependency array because in rotation it causes the map to wrongly update... To be investigated -> comment on FP-6758
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, fields]);

    useEffect(() => {
        if (map && selectedFields) {
            if (!map.loaded()) {
                map.once('idle', () => {
                    addSelectedPolygonLayer();
                });
            } else {
                addSelectedPolygonLayer();
            }
        }
    }, [map, selectedFields, addSelectedPolygonLayer]);

    return { addPolygonLayer, addSelectedPolygonLayer, hoveredFeatureFieldOverview };
};
