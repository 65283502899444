import { Modal } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { ReactNode } from 'react';
import { WarningBox } from '../WarningBox/WarningBox';

export type ConfirmModalCloseDataT = { confirmed: boolean };
export type ConfirmModalProps = Omit<ModalPropsT<never, ConfirmModalCloseDataT>, 'data'> & {
    title: ReactNode;
    /** text displayed in the confirm button */
    confirmText: ReactNode;
    confirmLoading?: boolean;
    /** if type is warning, a Warning icon will be added. Default is 'confirm' */
    type?: 'confirm' | 'warning';
    /** text displayed in the cancel button (no button displayed if undefined) */
    cancelText?: ReactNode;
    /** <strong> tags are available for the translationKey */
    primaryText?: {
        // We require the key to handle the bold text in the component
        translationKey: string;
        translationValues?: Record<string, string | number | undefined>;
    };
    /** <strong> tags are available for the translationKey */
    secondaryText?: {
        // We require the key to handle the bold text in the component
        translationKey: string;
        translationValues?: Record<string, string | number | undefined>;
    };
    className?: string;
    size?: 'small' | 'medium' | 'large' | null;
};

export const ConfirmModal = ({
    isOpen,
    onClose,
    primaryText,
    secondaryText,
    title,
    type = 'confirm',
    confirmText,
    confirmLoading,
    cancelText,
    className,
    size = 'small',
}: ConfirmModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            size={size}
            className={className}
            open={isOpen}
            onClose={() => onClose?.({ confirmed: false })}
            onConfirm={() => onClose?.({ confirmed: true })}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{title}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    {primaryText && (
                        <WarningBox displayWarningIcon={type === 'warning'}>
                            <Trans t={t} i18nKey={primaryText.translationKey} values={primaryText?.translationValues} />
                        </WarningBox>
                    )}
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <Trans
                            t={t}
                            i18nKey={secondaryText?.translationKey}
                            values={secondaryText?.translationValues}
                        />
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    {cancelText && <Modal.Cancel disabled={confirmLoading}>{cancelText}</Modal.Cancel>}
                    <Modal.Confirm loading={confirmLoading}>{confirmText}</Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
