import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { usePacImportActionPageStyles } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/PacImportActionPage.style';
import { usePacImportActionPageLogic } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/usePacImportActionPageLogic';
import ActionPage from '@modules/encoding/shared/components/ActionPage/ActionPage';
import { MapView } from '@shared/map/MapView';
import { usePacImportActionPageMap } from './usePacImportActionMap';
import { ValidateRotationConfirmModal } from '../../components/ValidateRotationConfirmModal/ValidateRotationConfirmModal';
import MapPopup from '@shared/components/MapPopup/MapPopup';

export const PacImportActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = usePacImportActionPageStyles();
    const {
        canContinue,
        isLoading,
        onConfirm,
        hasFields,
        fields,
        openValidateRotationModal,
        onValidateRotationWarningModalClose,
        onValidateRotationWarningModalConfirm,
        farmCoordinates,
        readOnly,
    } = usePacImportActionPageLogic({ navigate });
    const { calculateCentroid, polygons, helpText, drawMode, editMode, hoveredFeatureFieldOverview } =
        usePacImportActionPageMap(fields);

    return (
        <>
            <ActionPage
                isLoading={isLoading}
                classes={{ body: classes.body }}
                rightButtonProps={{
                    disabled: !canContinue,
                    onClick: onConfirm,
                    children: t('encoding-rotation.pac-file.action-page.confirm-button'),
                }}
            >
                {hasFields ? (
                    <MapView
                        hasControls
                        isDrawable
                        readOnly={readOnly}
                        coordinates={calculateCentroid(polygons)}
                        helpText={t(helpText)}
                        hasOpendata={!drawMode && !editMode}
                    />
                ) : (
                    !isLoading && (
                        <MapView
                            hasControls
                            isDrawable
                            readOnly={readOnly}
                            coordinates={farmCoordinates}
                            helpText={t(helpText)}
                            hasOpendata={!drawMode && !editMode}
                        />
                    )
                )}
                <MapPopup hoveredFeatureFieldOverview={hoveredFeatureFieldOverview} />
            </ActionPage>
            <ValidateRotationConfirmModal
                open={openValidateRotationModal}
                onClose={onValidateRotationWarningModalClose}
                onConfirm={onValidateRotationWarningModalConfirm}
            />
        </>
    );
};
