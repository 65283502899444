import { FieldPolygonT } from '@shared/entities/field/field.types';
import useMapPopupLogic from './MapPopup.logic';
import useMapPopup from './useMapPopup';

export type MapPopupProps = {
    hoveredFeatureFieldOverview: FieldPolygonT | undefined;
};

const MapPopup: React.FC<MapPopupProps> = ({ hoveredFeatureFieldOverview }) => {
    const { popupContent, openedPopupProps } = useMapPopupLogic(hoveredFeatureFieldOverview);

    useMapPopup(popupContent, openedPopupProps, hoveredFeatureFieldOverview);

    return null;
};

export default MapPopup;
