import { useMapPopupStyles } from './MapPopup.style';
import Paper from '@mui/material/Paper';

export type MapPopupProps = {
    children: React.ReactNode;
};

const MapPopupContainer = ({ children }: MapPopupProps) => {
    const { classes } = useMapPopupStyles();

    return <Paper className={classes.popupContainer}>{children}</Paper>;
};

export default MapPopupContainer;
