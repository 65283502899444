import mapboxgl, { Anchor, Offset } from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { useMapPopupStyles } from './MapPopup.style';
import { useContext, useEffect, useRef } from 'react';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import { OpenedMapPopupPropsT } from './MapPopup.types';
import { MapContext as MapContext_v2 } from '@shared/map2/MapProvider';
import { MapContext as MapContext_v1 } from '@shared/map/utils/MapProvider';
import MapPopupContainer from './MapPopupContainer';

const useMapPopup = (
    content: React.ReactNode,
    openedProps: OpenedMapPopupPropsT | null,
    hoveredFeature: FieldPolygonT | undefined,
): void => {
    const { classes } = useMapPopupStyles();

    const popupId = useRef<mapboxgl.Popup | null>(null);

    const mapContext_v1 = useContext(MapContext_v1);
    const mapContext_v2 = useContext(MapContext_v2);

    const mapContext = mapContext_v1?.map ? mapContext_v1 : mapContext_v2;
    const map = mapContext?.map as mapboxgl.Map | null;

    mapContext?.mapContainerRef?.current?.classList.add(classes.mapContainer);

    useEffect(() => {
        const createMapPopups = (
            coordinates: [number, number],
            anchor: Anchor | undefined,
            offset: Offset | null | undefined,
        ) => {
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                anchor,
                offset,
            });

            const placeholder = document.createElement('div');
            const root = createRoot(placeholder);
            root.render(<MapPopupContainer>{content}</MapPopupContainer>);

            if (map) popup.setLngLat(coordinates).setDOMContent(placeholder).addTo(map);

            return popup;
        };

        if (!map) return;

        if (openedProps && hoveredFeature)
            popupId.current = createMapPopups(
                [openedProps?.lng, openedProps?.lat],
                openedProps?.anchor,
                openedProps?.offset,
            );

        return () => {
            popupId.current?.remove();
            if (map && map.getCanvas()) map.getCanvas().style.cursor = '';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        map,
        content,
        openedProps?.anchor,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        openedProps?.offset.join(),
        openedProps?.lat,
        openedProps?.lng,
        hoveredFeature,
    ]);
};

export default useMapPopup;
