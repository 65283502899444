import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFinaliseDuplicationModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: 0,
    },
    fieldCropListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    fieldCropList: {
        maxHeight: 230,
    },
    fieldCropInfo: {
        maxWidth: '70%',
    },
}));
