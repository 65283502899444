import entities from '@shared/entities';
import { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { useTranslation } from 'react-i18next';

const schema = z.object({
    is_organic: z.boolean().nullable(),
    tillage_id: z.number().nullable(),
    has_companion_crop: z.boolean().nullable(),
    companion_crop_id: z.number().nullable(),
});

export type GlobalCharacteristicsFormData = z.infer<typeof schema>;

export const useGlobalCharacteristicsFormLogic = () => {
    const { seasonId } = useParams<TechnicalItineraryRouteParamsT['globalCharacteristics']>();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['globalCharacteristics']>();
    const farmSeasonId = Number(seasonId);
    const { currentFarm } = useCurrentFarm();
    const { t } = useTranslation();

    /* -------------------------------- Entities -------------------------------- */
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: farmSeasonId });
    const { staticTillagePracticeState } = entities.staticTillagePractice.useState();
    const [updateGlobalCharacteristics] = entities.fieldCrop.useUpdateGlobalCharacteristics();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    const currentFieldCrop = fieldCropState.getById(Number(fieldCropId));
    /* -------------------------------- Form -------------------------------- */
    const methods = useForm<typeof schema>({
        schema,
        defaultValues: {
            is_organic: null,
            tillage_id: null,
            has_companion_crop: null,
            companion_crop_id: null,
        },
        displaySnackbarOnSchemaError: true,
    });

    const { getValues, reset, setValue, watch } = methods;

    useEffect(() => {
        if (!fieldCropState.isLoading) {
            reset({
                is_organic: currentFieldCrop?.is_organic,
                tillage_id: currentFieldCrop?.tillage_id,
                has_companion_crop: currentFieldCrop?.has_companion_crop,
                companion_crop_id: currentFieldCrop?.companion_crop_id,
            });
        }
        // eslint-disable-next-line
    }, [fieldCropState.isLoading]);

    const selectTillageOptions = staticTillagePracticeState.list
        .map((option) => {
            return { value: option.id, label: t(option.translation_slug) };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const selectCropOptions = staticCropState.list
        .map((option) => {
            return { value: option.id, label: t(option.translation_slug) };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    const handleUpdateGlobalCharacteristics = () => {
        updateGlobalCharacteristics({
            body: getValues(),
            id: Number(fieldCropId),
            farmSeasonId: farmSeasonId,
        });
    };

    const hasCompanionCrop = watch('has_companion_crop');
    useEffect(() => {
        if (hasCompanionCrop === false) {
            setValue('companion_crop_id', null);
        }
    }, [hasCompanionCrop, setValue]);

    return {
        hasCompanionCrop,
        methods,
        selectTillageOptions,
        selectCropOptions,
        handleUpdateGlobalCharacteristics,
    };
};
