import { useTranslation } from 'react-i18next';
import { useGlobalCharacteristicsFormLogic } from './useGlobalCharacteristicsForm.logic';
import { useGlobalCharacteristicsFormStyle } from './useGlobalCharacteristicsForm.style';
import { FormProvider } from 'react-hook-form';
import { BooleanRadioGroup, FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

export const GlobalCharacteristicsForm = ({ isFarmTillageConventional }: { isFarmTillageConventional: boolean }) => {
    const { classes } = useGlobalCharacteristicsFormStyle();
    const { t } = useTranslation();
    const { methods, selectTillageOptions, selectCropOptions, handleUpdateGlobalCharacteristics, hasCompanionCrop } =
        useGlobalCharacteristicsFormLogic();

    return (
        <FormProvider {...methods}>
            <form className={classes.form}>
                {!isFarmTillageConventional && (
                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.global-characteristics.question.organic')}
                            tooltipTitleKey="encoding-technical-itinerary.global-characteristics.question.organic.tooltip.title"
                            tooltipTextKey="encoding-technical-itinerary.global-characteristics.question.organic.tooltip.text"
                        />
                        <BooleanRadioGroup name="is_organic" onChange={() => handleUpdateGlobalCharacteristics()} />
                    </FormRow>
                )}
                <FormRow>
                    <FormQuestion
                        question={t('encoding-technical-itinerary.global-characteristics.question.tillage-practice')}
                        tooltipTitleKey="encoding-technical-itinerary.global-characteristics.question.tillage-practice.tooltip.title"
                        tooltipTextKey="encoding-technical-itinerary.global-characteristics.question.tillage-practice.tooltip.text"
                    />
                    <FormAutocomplete
                        name="tillage_id"
                        placeholder={t(
                            'encoding-technical-itinerary.global-characteristics.placeholder.tillage-practice',
                        )}
                        onChange={() => handleUpdateGlobalCharacteristics()}
                        options={selectTillageOptions}
                        multiple={false}
                    />
                </FormRow>

                <FormRow>
                    <FormQuestion
                        question={t('encoding-technical-itinerary.global-characteristics.question-has-companion-crop')}
                        tooltipTitleKey="encoding-technical-itinerary.global-characteristics.question-has-companion-crop-tooltip-title"
                        tooltipTextKey="encoding-technical-itinerary.global-characteristics.question-has-companion-crop-tooltip-text"
                    />
                    <BooleanRadioGroup name="has_companion_crop" onChange={() => handleUpdateGlobalCharacteristics()} />
                </FormRow>

                {hasCompanionCrop ? (
                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.global-characteristics.question-companion-crop')}
                            tooltipTitleKey="encoding-technical-itinerary.global-characteristics.question-companion-crop-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.global-characteristics.question-companion-crop-tooltip-text"
                        />
                        <FormAutocomplete
                            name="companion_crop_id"
                            placeholder={t(
                                'encoding-technical-itinerary.global-characteristics.question-companion-crop-placeholder',
                            )}
                            onChange={() => handleUpdateGlobalCharacteristics()}
                            options={selectCropOptions}
                            multiple={false}
                        />
                    </FormRow>
                ) : null}
            </form>
        </FormProvider>
    );
};
