import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMapPopupStyles = makeStyles()((theme) => ({
    mapContainer: {
        '.mapboxgl-popup': {
            border: 'none',
            boxShadow: 'none',
            pointerEvents: 'none',
        },
        '.mapboxgl-popup-content': {
            backgroundColor: 'transparent',
            padding: 0,
            boxShadow: 'none',
            pointerEvents: 'none',
        },
        '.mapboxgl-popup-tip': {
            border: 'none',
        },
        '.mapboxgl-popup-close-button': {
            display: 'none',
        },
    },

    popupContainer: {
        minWidth: '250px',
        height: '100%',
        backgroundColor: 'white',
        boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
        borderRadius: '8px',
        zIndex: 1000,
        padding: theme.spacing(1),
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px',
    },
    popupHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5),
    },
    popupContent: {},
    fieldName: {
        color: '#2E413D',
        fontWeight: 800,
        fontSize: '1.5em',
    },
    popupFieldContainerItem: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#EFF2F3',
        padding: theme.spacing(0.5),
        borderRadius: '5px',
        marginBottom: theme.spacing(0.5),
    },
    coloredSide: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '5px',
        height: '100%',
        backgroundColor: '#42BD7F',
        borderRadius: '5px 0 0 5px',
        marginRight: theme.spacing(0.5),
    },
    popupFieldItemContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '5px',
        gap: theme.spacing(1),
    },
    popupFieldRowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    harvestYear: {
        fontWeight: 700,
        fontSize: '1.1rem',
        color: '#42BD7F',
    },
    organicChip: {
        backgroundColor: '#42BD7F',
        color: 'white',
        padding: '2px 3px',
        borderRadius: '5px',
    },
    areaTill: {
        color: '#2E413D',
        fontWeight: 800,
        fontSize: '1.4em',
    },
    cropChip: {
        color: '#2E413D',
        fontWeight: 800,
        fontSize: '1.3em',
        padding: '2px 5px',
        borderRadius: '5px',
        border: '1px solid #2E413D',
    },
    popupIconsContainer: {
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        gap: theme.spacing(0.5),
        borderRadius: '5px',
    },
    icon: {
        width: '30px',
        height: '30px',
    },
}));
