import { FieldT } from '@shared/entities';
import { useGetMapPopupfieldQuery } from '@modules/encoding/api/MapPopupFieldOverview/MapPopupFieldOverviewApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMapPopupStyles } from './MapPopup.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Loader } from '@soil-capital/ui-kit/components';
import { useCallback, useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react';
import { MapContext as MapContext_v2 } from '@shared/map2/MapProvider';
import { MapContext as MapContext_v1 } from '@shared/map/utils/MapProvider';
import { useTranslation } from 'react-i18next';
import turfCentroid from '@turf/centroid';
import turfDistance from '@turf/distance';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import { anchorT, MapPopupContentT, mouseZoneNameT, OpenedMapPopupPropsT, GridZoneT, GridZone } from './MapPopup.types';
import { IconAgroforestry, IconCover, IconDrainage, IconIrrigation } from '@soil-capital/ui-kit/icons';
import mapboxgl from 'mapbox-gl';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

const useMapPopupLogic = (feature: FieldPolygonT | undefined) => {
    const { currentSeasonId } = useCurrentSeasonId();

    const { data, isLoading, isFetching } = useGetMapPopupfieldQuery(
        feature?.id != null ? { fieldId: feature?.id, farmSeasonId: currentSeasonId } : skipToken,
    );

    const openedPopupProps = usePopupPositionComputation(feature);

    const popupContent = useMemo(
        () => <MapPopupContent isLoadingFieldData={isFetching} data={data} />,
        [isFetching, data],
    );

    return { popupContent: popupContent, openedPopupProps: openedPopupProps };
};

const MapPopupContent: React.FC<MapPopupContentT> = ({ isLoadingFieldData, data }) => {
    const { classes } = useMapPopupStyles();
    const { t } = useTranslation();

    const [isDataLoading, setIsDataLoading] = useState(true);

    useEffect(() => {
        if (!isLoadingFieldData) setIsDataLoading(false);
    }, [isLoadingFieldData]);

    if (isDataLoading)
        return (
            <div className={classes.loaderContainer}>
                <Loader />
            </div>
        );
    else
        return (
            <>
                <div className={classes.popupHeader}>
                    <Typography variant="caps" className={classes.fieldName}>
                        {data?.[0]?.field_name}
                    </Typography>
                </div>
                {data?.map((fieldYear) => (
                    <div key={fieldYear.harvest_year} className={classes.popupFieldContainerItem}>
                        <div className={classes.coloredSide} />
                        <div className={classes.popupFieldItemContent}>
                            <div className={classes.popupFieldRowContainer}>
                                <Typography variant="caps" className={classes.harvestYear}>
                                    {t('constants.harvest')} {fieldYear.harvest_year}
                                </Typography>
                                {fieldYear.is_organic && (
                                    <Typography variant="caps" className={classes.organicChip}>
                                        {t('constants.organic')}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.popupFieldRowContainer}>
                                <Typography variant="caps" className={classes.areaTill}>
                                    {fieldYear.area} HA{fieldYear.tillage_name ? ` - ${fieldYear.tillage_name}` : ''}
                                </Typography>
                            </div>
                            {fieldYear.crop_name && (
                                <div className={classes.popupFieldRowContainer}>
                                    <Typography variant="caps" className={classes.cropChip}>
                                        {t(`db.crop.slug-${fieldYear.crop_name}`)}
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.popupFieldRowContainer}>
                                {fieldYear.has_agroforestry ||
                                fieldYear.is_irrigated ||
                                fieldYear.has_drainage ||
                                fieldYear.cover_crops ? (
                                    <div className={classes.popupIconsContainer}>
                                        {fieldYear.has_agroforestry ? <IconAgroforestry color="success" /> : <></>}
                                        {fieldYear.is_irrigated ? <IconIrrigation color="primary" /> : <></>}
                                        {fieldYear.has_drainage ? <IconDrainage color="primary" /> : <></>}
                                        {fieldYear.cover_crops ? <IconCover color="success" /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
};

const usePopupPositionComputation = (feature: FieldPolygonT | undefined) => {
    const mapContext_v1 = useContext(MapContext_v1);
    const mapContext_v2 = useContext(MapContext_v2);

    const mapContext = mapContext_v1?.map ? mapContext_v1 : mapContext_v2;
    const map = mapContext?.map as mapboxgl.Map | null;

    const [computedGridZones, setComputedGridZones] = useState<GridZoneT | null>(null);
    const [openedPopupProps, setOpenedPopupProps] = useState<OpenedMapPopupPropsT | null>(null);

    const useWindowSize = () => {
        const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };

        useLayoutEffect(() => {
            window.addEventListener('resize', updateSize);
            return () => window.removeEventListener('resize', updateSize);
        }, []);

        return size;
    };

    const [windowX, windowY] = useWindowSize();

    /**
     * Split the map into zones so we can check in wich zone the mouse is + where is the opposite direction (target).
     * Each time the map sizes change, the grid zones have to be computed.
     */
    const _computeGridZones = useCallback((map: mapboxgl.Map | null): GridZoneT => {
        const canva = map?.getCanvas();

        if (!canva) {
            return {} as GridZoneT;
        }

        const canvaSize = { x: Number(canva.style.width.slice(0, -2)), y: Number(canva.style.height.slice(0, -2)) };

        // Compute gridZones on canva
        const xZoneWidth = canvaSize.x / 3;
        const yZoneHeight = canvaSize.y / 3;
        const leftInfinite = -10000;
        const rightInfinite = 10000;
        const topInfinite = -10000;
        const bottomInfinite = 10000;
        const topYs = { minY: topInfinite, maxY: yZoneHeight };
        const middleYs = { minY: yZoneHeight, maxY: 2 * yZoneHeight };
        const bottomYs = { minY: 2 * yZoneHeight, maxY: bottomInfinite };
        const rightXs = { minX: 2 * xZoneWidth, maxX: rightInfinite };
        const leftXs = { minX: leftInfinite, maxX: xZoneWidth };
        const middleXs = { minX: xZoneWidth, maxX: 2 * xZoneWidth };

        const GridZones = {
            bottomLeft: { ...bottomYs, ...leftXs, targetX: 3 * xZoneWidth, targetY: 0 },
            bottom: { ...bottomYs, ...middleXs, targetX: 1.5 * xZoneWidth, targetY: 0 },
            bottomRight: { ...bottomYs, ...rightXs, targetX: 0, targetY: 0 },
            topLeft: { ...topYs, ...leftXs, targetX: 3 * xZoneWidth, targetY: 3 * yZoneHeight },
            top: { ...topYs, ...middleXs, targetX: 1.5 * xZoneWidth, targetY: 3 * yZoneHeight },
            topRight: { ...topYs, ...rightXs, targetX: 0, targetY: 3 * yZoneHeight },
            left: {
                ...middleYs,
                minX: leftInfinite,
                maxX: canvaSize.x / 2,
                targetX: 3 * xZoneWidth,
                targetY: 1.5 * yZoneHeight,
            },
            right: {
                ...middleYs,
                minX: canvaSize.x / 2,
                maxX: rightInfinite,
                targetX: 0,
                targetY: 1.5 * yZoneHeight,
            },
        };

        return GridZones;
    }, []);

    useEffect(() => {
        if (!mapContext?.mapContainerRef.current) {
            return; // should not happen
        }

        // Compute grid zones on load + on window resize
        setComputedGridZones(_computeGridZones(map));
    }, [map, windowX, windowY, _computeGridZones, mapContext]);

    /**
     * Get in which zone of the canva the polygon is located + get the opposite direction point (target)
     */
    const _getPolygonZone = useCallback(
        (
            map: mapboxgl.Map,
            feature: FieldT['polygon'] | undefined,
        ): { name: mouseZoneNameT; targetPoint: { x: number; y: number } } => {
            const defaultZoneName: mouseZoneNameT = 'topRight';
            const defaultZone = { name: defaultZoneName, targetPoint: { x: 1000, y: 0 } };
            if (!computedGridZones) {
                // initialization error
                return defaultZone;
            }

            if (!feature) {
                return defaultZone;
            }

            // Get centroid of polygon hovered.
            const polygonLngLatCenter = turfCentroid(feature);
            const { x: xPx, y: yPx } = map.project([
                polygonLngLatCenter.geometry.coordinates[0],
                polygonLngLatCenter.geometry.coordinates[1],
            ]);

            // get canvas zone on wich the centroid point is.
            const gridZone = Object.entries(computedGridZones).find(([, xyBox]) => {
                if (xPx > xyBox.minX && xPx < xyBox.maxX && yPx > xyBox.minY && yPx < xyBox.maxY) {
                    return true;
                }
                return false;
            });
            if (!gridZone) {
                // error: Mouse doesn't fit the canva?
                return defaultZone;
            }

            const [zoneName, { targetX, targetY }] = gridZone as [mouseZoneNameT, GridZone];
            return { name: zoneName, targetPoint: { x: targetX, y: targetY } };
        },
        [computedGridZones],
    );

    /** get the feature point the closest to a target point -> the feature point on which the popup should display  */
    const _getComputedPopupPosition = useCallback(
        (feature: FieldPolygonT, targetPoint: mapboxgl.LngLat): { lng: number; lat: number } => {
            let nearestCoord: [number, number] | null = null;
            let smallestDist = Infinity;
            feature.geometry.coordinates?.[0].forEach((coord) => {
                const dist = turfDistance(coord, targetPoint.toArray());
                if (dist < smallestDist) {
                    nearestCoord = [coord[0], coord[1]];
                    smallestDist = dist;
                }
            });
            if (nearestCoord === null) {
                // should not happen
                return { lat: 0, lng: 0 };
            }
            return { lng: nearestCoord[0], lat: nearestCoord[1] };
        },
        [],
    );

    /** return some popup properties (anchor, offset) depending on where the mouse is placed in the canva (related to computed zones) */
    const _getComputedZonePopupProps = useCallback((mapZoom: number, mouseZoneName: mouseZoneNameT) => {
        const offset = -5;

        let computedAnchor: anchorT = undefined;
        let computedOffset: [number, number] = [0, 0];
        switch (mouseZoneName) {
            case 'left':
                computedAnchor = 'left';
                computedOffset = [-offset, 0];
                break;
            case 'topLeft':
                computedAnchor = 'top-left';
                computedOffset = [-offset, -offset];
                break;
            case 'top':
                computedAnchor = 'top';
                computedOffset = [0, -offset];
                break;
            case 'topRight':
                computedAnchor = 'top-right';
                computedOffset = [offset, -offset];
                break;
            case 'right':
                computedAnchor = 'right';
                computedOffset = [offset, 0];
                break;
            case 'bottomRight':
                computedAnchor = 'bottom-right';
                computedOffset = [offset, offset];
                break;
            case 'bottom':
                computedAnchor = 'bottom';
                computedOffset = [0, offset];
                break;
            case 'bottomLeft':
                computedAnchor = 'bottom-left';
                computedOffset = [-offset, offset];
                break;
        }
        return { computedAnchor, computedOffset };
    }, []);

    useEffect(() => {
        if (map && feature) {
            const mouseZone = _getPolygonZone(map, feature);
            const targetPoint = map.unproject([mouseZone.targetPoint.x, mouseZone.targetPoint.y]);
            const { lat, lng } = _getComputedPopupPosition(feature, targetPoint);
            const zoom = map.getZoom();
            const { computedAnchor, computedOffset } = _getComputedZonePopupProps(zoom, mouseZone.name);
            setOpenedPopupProps({
                lng,
                lat,
                anchor: computedAnchor,
                offset: computedOffset,
            });
        }
    }, [_getComputedPopupPosition, _getComputedZonePopupProps, _getPolygonZone, feature, map]);

    return openedPopupProps;
};

export default useMapPopupLogic;
