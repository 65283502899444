import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { Modal } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useReplaceCropModalLogic } from './useReplaceCropModalLogic';

export type ReplaceCropModalOpenProps = { existingFieldCropIds: number[]; newCropId: number; newFieldId: number };
export type ReplaceCropModalCloseProps = void;
export type ReplaceCropModalProps = {
    replaceCropModalController: ModalPropsT<ReplaceCropModalOpenProps, ReplaceCropModalCloseProps>;
};

export const ReplaceCropModal = ({ replaceCropModalController }: ReplaceCropModalProps) => {
    const { t } = useTranslation();
    const {
        fieldName,
        newCropName,
        previousCropName,
        addCrop,
        addFieldCropLoading,
        replaceCrop,
        replaceCropLoading,
        previousCropCount,
    } = useReplaceCropModalLogic({
        controller: replaceCropModalController,
    });

    return (
        <Modal
            size={'small'}
            open={replaceCropModalController.isOpen}
            onClose={(reason) => {
                reason === 'closeClick'
                    ? replaceCropModalController.onClose?.()
                    : reason === 'cancelClick'
                    ? addCrop()
                    : null;
            }}
            onConfirm={replaceCrop}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-rotation.modal-replace-crop.title')}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <WarningBox>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.modal-replace-crop.information'}
                            values={{
                                fieldName,
                                previousCropName,
                                count: previousCropCount,
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.modal-replace-crop.description'}
                            values={{ newCropName, count: previousCropCount, cropCount: previousCropCount + 1 }}
                        />
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel loading={addFieldCropLoading} disabled={replaceCropLoading}>
                        {t('encoding-rotation.modal-replace-crop.add-crop-action')}
                    </Modal.Cancel>
                    <Modal.Confirm loading={replaceCropLoading} disabled={addFieldCropLoading}>
                        {t('encoding-rotation.modal-replace-crop.replace-crop-action')}
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
